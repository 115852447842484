/* src/components/Home.css */
.dashboard-container {
    display: flex;
    min-height: calc(100vh - 60px);
    overflow-x: hidden;
    position: relative;
  }
  
  .dashboard-main {
    flex: 1;
    padding: 2rem;
    background-color: #f8f9fa;
    box-sizing: border-box;
  }
  
  .welcome-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
  }
  
  .welcome-section h1 {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 0.8rem;
    line-height: 1.2;
  }
  
  .subtitle {
    font-size: 1.2rem;
    color: #7f8c8d;
    margin-bottom: 2.5rem;
    font-weight: 400;
  }
  
  .dashboard-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 2rem;
    margin-bottom: 3rem;
  }
  
  .card {
    background: white;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
  }
  
  .card:hover {
    transform: translateY(-3px);
  }
  
  .card h3 {
    color: #2c3e50;
    margin-bottom: 1.5rem;
    font-size: 1.3rem;
    font-weight: 600;
  }
  
  .action-buttons {
    display: flex;
    gap: 1.2rem;
    margin-top: 1.5rem;
  }
  
  .action-btn {
    padding: 0.8rem 1.8rem;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .action-btn.primary {
    background-color: #6fc3b2;
    color: white;
    box-shadow: 0 2px 4px rgba(111, 195, 178, 0.2);
  }
  
  .action-btn.primary:hover {
    background-color: #57b0a1;
    box-shadow: 0 4px 8px rgba(111, 195, 178, 0.3);
  }
  
  .action-btn.secondary {
    background-color: #3498db;
    color: white;
    box-shadow: 0 2px 4px rgba(52, 152, 219, 0.2);
  }
  
  .action-btn.secondary:hover {
    background-color: #2980b9;
    box-shadow: 0 4px 8px rgba(52, 152, 219, 0.3);
  }
  
  .resources-section {
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 1px solid #eee;
  }
  
  .resources-section h3 {
    color: #2c3e50;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    font-weight: 600;
  }
  
  .resource-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
  }
  
  .resource-card {
    background: white;
    border-radius: 10px;
    padding: 1.8rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
    text-decoration: none;
    color: #2c3e50;
    border-left: 4px solid #6fc3b2;
    display: block;
  }
  
  .resource-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .resource-card h4 {
    margin-bottom: 0.8rem;
    color: #2c3e50;
    font-size: 1.1rem;
    font-weight: 600;
  }
  
  .resource-card p {
    color: #7f8c8d;
    font-size: 0.95rem;
    line-height: 1.4;
  }
  
  .loading-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: rgba(255, 255, 255, 0.9);
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #6fc3b2;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 1.5rem;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .dashboard-main {
      padding: 1.5rem;
      margin-left: 0 !important;
      width: 100%;
    }
  
    .dashboard-container {
      display: block;
    }
    
    .dashboard-cards {
      grid-template-columns: 1fr;
    }
    
    .welcome-section h1 {
      font-size: 2.2rem;
    }
  }
  