/* src/components/DashboardLayout.css */
.home-wrapper {
  display: flex;
  min-height: 100vh;
  background-color: #f4f6f8;
  font-family: 'Inter', 'Segoe UI', sans-serif;
}

.main-layout {
  flex: 1;
  margin-left: 250px; /* desktop sidebar width */
  transition: margin 0.3s ease;
}

.content {
  flex: 1;
  padding: 40px;
  background-color: #ffffff;
  color: #2f2f2f;
  font-size: 16px;
  line-height: 1.6;
}

/* On mobile, remove left margin and add top margin for mobile header */
@media (max-width: 768px) {
  .main-layout {
    width: 100%;
    margin-left: 0;
    margin-top: 60px;
  }
}
