/* src/components/LeftMenu.css */
.left-menu-layout {
  position: relative;
}

/* Desktop sidebar styles – always visible */
.left-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 250px;
  background-color: #3e3f49; /* neutral dark */
  color: #ffffff;
  padding: 20px;
  font-family: "Inter", "Segoe UI", sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 1100;
}

/* Sidebar content styling */
.menu-header {
  margin-bottom: 30px;
}

.logo {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #b9aedc; /* muted lavender */
}

.welcome {
  font-size: 14px;
  color: #ccccd8; /* soft neutral */
}

.menu-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-links li {
  margin-bottom: 18px;
}

.menu-links a {
  color: #f0f0f0;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.2s ease;
}

.menu-links a:hover {
  color: #6fc3b2; /* minty hover accent */
}

.logout {
  margin-top: auto;
}

.logout button {
  background: none;
  border: none;
  color: #f0f0f0;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.2s ease;
}

.logout button:hover {
  color: #6fc3b2;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  /* Mobile header (hamburger & title) – fixed at top */
  .mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #3e3f49;
    padding: 0 20px;
    color: #fff;
    font-family: "Inter", "Segoe UI", sans-serif;
    z-index: 1200;
  }

  .hamburger-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
  }

  /* Mobile sidebar: off-canvas and slides in below header */
  .left-menu {
    position: fixed;
    top: 60px;
    left: 0;
    height: calc(100vh - 60px);
    width: 250px;
    background-color: #3e3f49;
    padding: 20px;
    font-family: "Inter", "Segoe UI", sans-serif;
    transition: transform 0.3s ease-in-out;
    /* Raise z-index above mobile header */
    z-index: 1300;
    transform: translateX(-100%);
  }

  .left-menu.open {
    transform: translateX(0);
  }

  /* Backdrop for mobile: below sidebar, above content */
  .backdrop {
    display: block;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background: rgba(0, 0, 0, 0.5);
    /* Lower z-index than sidebar */
    z-index: 1250;
  }

  /* Center sidebar content on mobile */
  .menu-header,
  .menu-links,
  .logout {
    text-align: left;
  }

  .menu-links li {
    margin-bottom: 12px;
  }
}

/* Desktop: hide mobile header */
@media (min-width: 769px) {
  .mobile-header {
    display: none;
  }
  .left-menu {
    transform: translateX(0);
  }
}
